
.fresh-auto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.sort-main {
    margin: 10px 0;
    height: calc(100% - 92px);
}
